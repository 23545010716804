<template>
  <div ref="additional" class="additional-info">
    <!--<HeaderBanner :content="bannerContent" />-->
    <div class="additional-info__content">
      <div class="additional-info__wrapper">
        <NuxtLink
          :href="`tel:${_T('@Call center number')}`"
          class="additional-info__call ringo-tel"
        >
          <div class="additional-info__image">
            <svg
              width="22"
              height="22"
              viewBox="0 0 22 22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M20.4763 16.3914L16.4302 13.6982C16.1829 13.5346 15.8827 13.47 15.5896 13.5175C15.2966 13.565 15.0323 13.7211 14.8498 13.9545L13.6697 15.4671C13.5947 15.5634 13.4878 15.63 13.3681 15.6548C13.2484 15.6796 13.1237 15.6611 13.0164 15.6026L12.7951 15.4797C11.4719 14.7595 10.2788 13.8236 9.26542 12.7109C8.15156 11.695 7.21191 10.5042 6.48376 9.18585L6.36787 8.96108C6.30619 8.85641 6.285 8.73288 6.30829 8.61372C6.33157 8.49456 6.39772 8.388 6.4943 8.31406L8.01157 7.13869C8.24426 6.95583 8.4002 6.69304 8.44891 6.40168C8.49762 6.11033 8.4356 5.81134 8.27498 5.56313L5.56708 1.52339C5.40056 1.2723 5.14317 1.09506 4.84864 1.02871C4.55412 0.962354 4.24526 1.01202 3.9866 1.16733L2.29024 2.18303C1.76061 2.49686 1.37208 3.00146 1.20494 3.59263C0.593823 5.80891 1.05743 9.63437 6.70501 15.2696C11.1936 19.7515 14.5337 20.9563 16.8306 20.9563C17.3608 20.9596 17.8889 20.891 18.4006 20.7525C18.9933 20.5834 19.4985 20.1944 19.8125 19.6654L20.8335 17.9775C20.9883 17.7183 21.0376 17.4099 20.9711 17.1156C20.9047 16.8213 20.7276 16.5636 20.4763 16.3956V16.3914Z"
                stroke="#FAC748"
                stroke-width="2"
              />
            </svg>
          </div>
          <span class="additional-info__item-name">
            {{ _T("@Call center number") }}
          </span>
        </NuxtLink>
        <RenderCacheable :max-age="86400" :cache-key="getUserLanguage.name">
          <HeaderLinks />
        </RenderCacheable>
      </div>
    </div>
  </div>
</template>

<script setup>
import HeaderBanner from "~/modules/header/sections/HeaderBanner.vue";
import { addScopedEventListener } from "~~/utils/eventListener";
import HeaderLinks from "~/modules/header/sections/HeaderLinks.vue";
import { useLanguageFilterStore } from "~/store/languageFilterStore";

const emits = defineEmits(["getHeightAdditional"]);

const props = defineProps({
  bannerContent: { type: Object, required: true },
});

const languageStore = useLanguageFilterStore();
const { getUserLanguage } = storeToRefs(languageStore);

const additional = ref(null);

function getHeight() {
  emits("getHeightAdditional", additional.value.scrollHeight);
}

onMounted(() => {
  getHeight();
  addScopedEventListener(window, "resize", getHeight);
});
</script>

<style lang="scss" scoped>
.additional-info {
  &__content {
    background-color: white;

    padding: 4px 16px;

    @include bigMobile {
      display: none;
    }
  }

  &__wrapper {
    @extend %width-main;

    @include flex-container(row, space-between, center);

    margin: 0 auto;
  }

  &__section {
    @include flex-container(row, flex-start, center);

    gap: 64px;
  }

  &__call,
  &__location {
    @include flex-container(row, flex-start, center);

    gap: 8px;
  }

  &__item-name {
    @include font(18, 24, 700);
    color: var(--color-primary-yellow);
  }
}
</style>

<style lang="scss">
.additional-info__call {
  &.ringo-tel {
    @include flex-container(row, flex-start, center);

    gap: 8px;

    .additional-info__item-name {
      @include font(18, 24, 700);
      color: var(--color-primary-yellow);
    }
  }
}
</style>
